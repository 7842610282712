export function CanvaTemplateSection(props: {
  heading: string;
  body: () => React.ReactNode;
  image: () => React.ReactNode;
}) {
  return (
    <div id="canva-templates" className="bg-brand-seasalt py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="flex flex-col items-center gap-4 lg:flex-row lg:justify-between">
          <div className="mb-12 max-w-xl lg:mb-0">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              {props.heading}
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              {props.body()}
            </p>
          </div>

          <div className="h-60 w-full md:h-80 lg:h-96">{props.image()}</div>
        </div>
      </div>
    </div>
  );
}
