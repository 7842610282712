import WeddingGuestbook from "#client/images/wedding_guestbook.webp";
import DisposableCameras from "#client/images/disposable_cameras.webp";
import QrCodeSignage from "#client/images/qr_code_signage.webp";

const posts = [
  {
    id: 1,
    title: "Five Ways to Use QR Codes at Your Wedding",
    href: "https://eventshare.io/blog/five-ways-to-use-qr-codes-for-your-wedding",
    description:
      "QR codes are an innovative way to add convenience and fun to your big day! First up we have wedding invitations. Guests can scan the QR code to easily RSVP to your wedding.",
    imageUrl: QrCodeSignage,
    date: "May 11, 2024",
    datetime: "2024-05-11",
    category: {
      title: "QR Codes",
      href: "https://eventshare.io/blog/five-ways-to-use-qr-codes-for-your-wedding",
    },
  },
  {
    id: 2,
    title: "Alternative Wedding Guestbook Ideas",
    href: "https://eventshare.io/blog/alternative-wedding-guestbook",
    description:
      "Not sure what to do for your wedding guestbook? We got you covered! These guestbook ideas are way more fun than a traditional one, and we used three of them at our wedding.",
    imageUrl: WeddingGuestbook,
    date: "May 10, 2024",
    datetime: "2024-05-10",
    category: {
      title: "Guestbook",
      href: "https://eventshare.io/blog/alternative-wedding-guestbook",
    },
  },
  {
    id: 3,
    title: "Don't Use Disoble Cameras at Your Wedding",
    href: "https://eventshare.io/blog/dont-use-disposable-cameras-at-your-wedding",
    description:
      "I know the title is a bold claim, but please do not use disposable cameras at your wedding. They are fun, interactive, and trendy, but they should not be relied upon for an occasion as special and unique as a wedding.",
    imageUrl: DisposableCameras,
    date: "Apr 04, 2024",
    datetime: "2024-04-04",
    category: {
      title: "Disposable Cameras",
      href: "https://eventshare.io/blog/dont-use-disposable-cameras-at-your-wedding",
    },
  },
];

export function BlogSection() {
  return (
    <div id="blog" className="bg-brand-seashell py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            From the blog
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Learn how to get candid photos and videos from your wedding guests.
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article
              key={post.id}
              className="flex flex-col items-start justify-between"
            >
              <div className="relative w-full">
                <img
                  src={post.imageUrl}
                  alt=""
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="mt-8 flex items-center gap-x-4 text-xs">
                  <time dateTime={post.datetime} className="text-gray-500">
                    {post.date}
                  </time>
                  <a
                    href={post.category.href}
                    className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                  >
                    {post.category.title}
                  </a>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href={post.href}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                    {post.description}
                  </p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}
